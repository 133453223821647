module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "conversations-embed": "static-1.21376",
      "conversations-internal-schema": "static-1.10175",
      "head-dlb": "static-1.2518",
      "hs-test-utils": "static-1.5521",
      "hubspot-url-utils": "static-1.2016",
      "HubStyleTokens": "static-2.9079",
      "I18n": "static-7.1304",
      "jasmine-runner": "static-1.3901",
      "quartz": "static-1.4087",
      "react": "static-7.133",
      "sinon": "static-1.9",
      "webpack-env": "static-1.41",
      "isMobile": "static-1.7",
      "StyleGuideUI": "static-3.433",
      "enviro": "static-4.275",
      "hs-promise-rejection-tracking": "static-1.2904",
      "PortalIdParser": "static-2.254",
      "raven": "static-3.3217",
      "raven-hubspot": "static-1.3524",
      "bend-plugin-trellis-migration": "static-1.1099",
      "moment": "static-3.26",
      "immutable": "static-2.19",
      "quartz-core": "static-1.3306",
      "transmute": "static-2.29",
      "HeadJS": "static-2.518",
      "react-dom": "static-7.85",
      "foundations-components": "static-1.4240",
      "framer-motion": "static-1.30",
      "hs-test-utils-bend-plugin": "static-1.632",
      "msw": "static-1.39",
      "react-redux": "static-7.16",
      "redux": "static-4.16",
      "testing-library": "static-1.113",
      "i18n-data": "static-1.188",
      "metrics-js": "static-1.7129",
      "moment-timezone": "static-5.17",
      "react-utils": "static-2.3203",
      "jasmine": "static-4.1820",
      "quartz-core-utils": "static-1.954",
      "csstype": "static-1.8",
      "copilot-toolkit": "static-1.2696",
      "dispatcher": "static-1.105",
      "general-store": "static-6.20",
      "history": "static-4.15",
      "hub-http": "static-1.2732",
      "hub-http-janus": "static-1.586",
      "hub-http-shared-msw-handlers": "static-1.5410",
      "hubspotter-http": "static-1.2550",
      "hubspotter-http-shared-msw-handlers": "static-1.5408",
      "icons": "static-2.585",
      "mobile-manifest-mixins": "static-1.289",
      "platform-navigation-bootstrap": "static-1.9338",
      "quick-fetch": "static-1.1826",
      "react-rhumb": "static-1.14036",
      "react-router-dom": "static-5.26",
      "styled-components": "static-2.40",
      "ui-fonts": "static-1.327",
      "UIComponents": "static-3.6029",
      "bend-plugin-foundations-components": "static-1.508",
      "floating-ui": "static-1.20",
      "foundations-assets": "static-1.2312",
      "foundations-theming": "static-1.1449",
      "hs-story-utils": "static-1.6526",
      "react-aria": "static-1.32",
      "react-select-plus": "static-1.65",
      "react-virtualized": "static-2.67",
      "tanstack-table": "static-1.10",
      "ui-images": "static-2.766",
      "hoist-non-react-statics": "static-3.9",
      "chatspot-client-types": "static-1.6707",
      "chatspot-core": "static-1.11780",
      "ts-schema": "static-1.1953",
      "sassPrefix": "static-1.121",
      "platform-infra-nav-components": "static-1.5657",
      "classnames": "static-2.10",
      "cssUtils": "static-1.320",
      "HubStyle": "static-2.9389",
      "foundations-theming-base": "static-1.434",
      "react-input-autosize": "static-2.17",
      "hs-lodash": "static-4.9"
    },
    "depPathPrefixes": {
      "conversations-embed": "/conversations-embed/static-1.21376",
      "conversations-internal-schema": "/conversations-internal-schema/static-1.10175",
      "head-dlb": "/head-dlb/static-1.2518",
      "hs-test-utils": "/hs-test-utils/static-1.5521",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.2016",
      "HubStyleTokens": "/HubStyleTokens/static-2.9079",
      "I18n": "/I18n/static-7.1304",
      "jasmine-runner": "/jasmine-runner/static-1.3901",
      "quartz": "/quartz/static-1.4087",
      "react": "/react/static-7.133",
      "sinon": "/sinon/static-1.9",
      "webpack-env": "/webpack-env/static-1.41",
      "isMobile": "/isMobile/static-1.7",
      "StyleGuideUI": "/StyleGuideUI/static-3.433",
      "enviro": "/enviro/static-4.275",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2904",
      "PortalIdParser": "/PortalIdParser/static-2.254",
      "raven": "/raven/static-3.3217",
      "raven-hubspot": "/raven-hubspot/static-1.3524",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.1099",
      "moment": "/moment/static-3.26",
      "immutable": "/immutable/static-2.19",
      "quartz-core": "/quartz-core/static-1.3306",
      "transmute": "/transmute/static-2.29",
      "HeadJS": "/HeadJS/static-2.518",
      "react-dom": "/react-dom/static-7.85",
      "foundations-components": "/foundations-components/static-1.4240",
      "framer-motion": "/framer-motion/static-1.30",
      "hs-test-utils-bend-plugin": "/hs-test-utils-bend-plugin/static-1.632",
      "msw": "/msw/static-1.39",
      "react-redux": "/react-redux/static-7.16",
      "redux": "/redux/static-4.16",
      "testing-library": "/testing-library/static-1.113",
      "i18n-data": "/i18n-data/static-1.188",
      "metrics-js": "/metrics-js/static-1.7129",
      "moment-timezone": "/moment-timezone/static-5.17",
      "react-utils": "/react-utils/static-2.3203",
      "jasmine": "/jasmine/static-4.1820",
      "quartz-core-utils": "/quartz-core-utils/static-1.954",
      "csstype": "/csstype/static-1.8",
      "copilot-toolkit": "/copilot-toolkit/static-1.2696",
      "dispatcher": "/dispatcher/static-1.105",
      "general-store": "/general-store/static-6.20",
      "history": "/history/static-4.15",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-janus": "/hub-http-janus/static-1.586",
      "hub-http-shared-msw-handlers": "/hub-http-shared-msw-handlers/static-1.5410",
      "hubspotter-http": "/hubspotter-http/static-1.2550",
      "hubspotter-http-shared-msw-handlers": "/hubspotter-http-shared-msw-handlers/static-1.5408",
      "icons": "/icons/static-2.585",
      "mobile-manifest-mixins": "/mobile-manifest-mixins/static-1.289",
      "platform-navigation-bootstrap": "/platform-navigation-bootstrap/static-1.9338",
      "quick-fetch": "/quick-fetch/static-1.1826",
      "react-rhumb": "/react-rhumb/static-1.14036",
      "react-router-dom": "/react-router-dom/static-5.26",
      "styled-components": "/styled-components/static-2.40",
      "ui-fonts": "/ui-fonts/static-1.327",
      "UIComponents": "/UIComponents/static-3.6029",
      "bend-plugin-foundations-components": "/bend-plugin-foundations-components/static-1.508",
      "floating-ui": "/floating-ui/static-1.20",
      "foundations-assets": "/foundations-assets/static-1.2312",
      "foundations-theming": "/foundations-theming/static-1.1449",
      "hs-story-utils": "/hs-story-utils/static-1.6526",
      "react-aria": "/react-aria/static-1.32",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-virtualized": "/react-virtualized/static-2.67",
      "tanstack-table": "/tanstack-table/static-1.10",
      "ui-images": "/ui-images/static-2.766",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "chatspot-client-types": "/chatspot-client-types/static-1.6707",
      "chatspot-core": "/chatspot-core/static-1.11780",
      "ts-schema": "/ts-schema/static-1.1953",
      "sassPrefix": "/sassPrefix/static-1.121",
      "platform-infra-nav-components": "/platform-infra-nav-components/static-1.5657",
      "classnames": "/classnames/static-2.10",
      "cssUtils": "/cssUtils/static-1.320",
      "HubStyle": "/HubStyle/static-2.9389",
      "foundations-theming-base": "/foundations-theming-base/static-1.434",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "hs-lodash": "/hs-lodash/static-4.9"
    },
    "project": "conversations-embed",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};